import { Component, OnInit } from '@angular/core';
import { ActivatedRoute } from '@angular/router';
import { Papa } from 'ngx-papaparse';
import { NgxSpinnerService } from 'ngx-spinner';
import { finalize } from 'rxjs';
import { ExtractionPayloadDto, ExtractionsResponse } from 'src/app/_model/ExtractionPayloadDto';
import { Type } from 'src/app/_model/enum/Type';
import { ExtractionService } from 'src/app/_service/extraction.service';


@Component({
  selector: 'app-view-scrape',
  templateUrl: './view-scrape.component.html',
  styleUrls: ['./view-scrape.component.scss']
})
export class ViewScrapeComponent implements OnInit {
  extractionDataPayload:ExtractionPayloadDto={
    url: '',
    timeStamp: undefined,
    data: '',
    id: '',
    agent:{
      resolution:{
        width:undefined,
        height: undefined
      },
      options:{
        headers: null
      }
    },
    artifacts: [],
    outputAgents: {
      statusCode: 0,
      headers: null,
      extractions:[{
        request:{
          type:"",
          value:""},
        response: "",
      }]
    },
    extractionsResponse:[],
    webhook:{
      url:"",
      enabled: undefined,
      method: undefined,
      headers: undefined,
      successful: undefined,
      retry: undefined

    }
  }

  json: { htmlData: string[] } = { htmlData: [] };
  data:string;
  identifier:string;
  editorOptions = {theme: 'vs-dark', language: 'html', readOnly: true};
  code = '';
  fullExtraction: string[]=[];
  extractorExtractions: string[]=[];
  regexExtractions: string[]=[];
  headers={};
  imageUrl= '';


  constructor(private extractionService:ExtractionService,
              private spinner: NgxSpinnerService,
              private route: ActivatedRoute,
              private papa: Papa) {
     }

  ngOnInit(): void{
    this.spinner.show()
    this.route.paramMap.subscribe(
      paramMap=>{
        this.identifier = paramMap.get('id')
        this.fetchPayload(this.identifier)
      });
  }
  
  async fetchPayload (identifier:string){
    this.extractionService.getCrawlPayload(identifier).pipe(finalize(()=> this.spinner.hide()))
    .subscribe(res => {
      this.extractionDataPayload = res;
      if(this.extractionDataPayload?.extractionsResponse?.length > 0){
        this.getExtractions(this.extractionDataPayload?.extractionsResponse);
      }
    })
  }

  getExtractions (extractions:ExtractionsResponse[]){
    extractions.forEach((extraction) => {
      switch (extraction.request.type) {
        case Type.full:
          this.fullExtraction.push(...extraction.response);
          break;
        case Type.regex:
          this.regexExtractions.push(...extraction.response);
          break;
         default: 
          this.extractorExtractions.push(...extraction.response);
          break;
      }
    });
  }

  downloadData(selector:string){
    switch(selector){
      case 'extractor':
        this.json.htmlData = this.extractorExtractions;
        break;
      case 'full':
        this.json.htmlData = this.fullExtraction;
        break;
      case 'regex': 
        this.json.htmlData = this.regexExtractions;
        break;
    }
    const jsonData = JSON.stringify(this.json, null, 2);
    const blob = new Blob([jsonData], { type: 'application/json' });
    const url = window.URL.createObjectURL(blob);
    const a = document.createElement('a');
    a.href = url;
    a.download = this.extractionDataPayload.id+'_extraction_data.json'; 
    a.click();
    window.URL.revokeObjectURL(url);
  }

  downloadAsCSV(){
    const fileName = this.extractionDataPayload.id;
    const data = this.extractionDataPayload;
    
  const headers = Object.entries(data.outputAgents.headers)
    .map(([key, value]) => `${key}: ${value}`)
    .join('; ');

  const rows = data.extractionsResponse.flatMap((extraction: any) =>
    extraction.response.map((responseValue: string) => ({
      id: data.id,
      url: data.url,
      timestamp: data.timeStamp,
      headers: headers,
      request_type: extraction.request.type,
      request_value: extraction.request.value || '',
      data: responseValue,
    }))
  );

  const csv = this.papa.unparse(rows, {
    header: true,
  });

  const blob = new Blob([csv], { type: 'text/csv;charset=utf-8;' });

  const url = URL.createObjectURL(blob);
  const a = document.createElement('a');
  a.href = url;
  a.download = `${fileName}.csv`;
  a.style.display = 'none';
  document.body.appendChild(a);

  a.click();
  document.body.removeChild(a);
  URL.revokeObjectURL(url);
    console.log(this.extractionDataPayload)
  }

  setImgUrlModal(screenshotIndex:number){
    this.imageUrl = this.extractionDataPayload.artifacts[screenshotIndex];
  }
  

}
